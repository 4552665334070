import { Button, Group, Modal, Stack, Title } from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import UploadArea from "components/v2/UploadArea";
import { PlayerContext } from "context/Player/PlayerContext";
import React, { useContext, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Plus, X } from "tabler-icons-react";

export function TeleportPhotoCreateButton({idTeleport}) {

    const queryClient = useQueryClient();

    const playerContext = useContext(PlayerContext);

    const [opened, openedHandler] = useDisclosure(false);

    const uploadArea = useRef(null);

    function uploadImages() {
        uploadArea.current.files().forEach(file => {
            createPhotoMetadata(file);
        });
    }

    const {mutate: createPhotoMetadata} = useMutation((file) => {
        uploadArea.current.validate();
        
        let finalData = {};
        finalData.subtitle = file?.name?.replace(/\.[^/.]+$/, "")

        return BackendServiceV2.post('/photo', {...finalData, idTeleport: idTeleport})
    }, {
        onSuccess: (response, file) => {
            uploadArea.current.upload({
                mutate: async (files, config) => {
                    let formData = new FormData();
                    formData.append('data', file);

                    return BackendServiceV2.put('/photo/' + response.idPhoto + '/image', formData, config)
                },
                success: () => {
                    queryClient.invalidateQueries('teleport-data');
                    playerContext?.player?.reload();
                    
                    showNotification({
                        title: 'Imagem ' + response?.subtitle + ' salva com sucesso',
                        message: '',
                        icon: <Check />,
                        color: 'green',
                        autoClose: 5000,
                    });
                },
                error: (error) => {
                    showNotification({
                        title: 'Um erro ocorreu',
                        message: error?.response?.data?.message,
                        icon: <X />,
                        color: 'red',
                        autoClose: 5000,
                    });
        
                    BackendServiceV2.delete('/photo/' + response.idPhoto);
                }
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (<>
        <Button leftIcon={<Plus />} onClick={openedHandler.open}>Nova imagem</Button>

        <Modal
            opened={opened}
            onClose={openedHandler.close}
            title={
                <Group><Plus /><Title order={2}>Nova imagem</Title></Group>
            }
            centered
            size="lg"
        >
            <form>
                <Stack style={{width: '100%'}}>
                    <UploadArea 
                        rejectMessage="Erro"
                        maxSize={1024 * 5}
                        accept={[MIME_TYPES.jpeg, MIME_TYPES.png]}
                        multiple={true}
                        ref={uploadArea}
                    />

                    <Button onClick={uploadImages}>Salvar</Button>
                </Stack>
            </form>
        </Modal>
    </>)
}