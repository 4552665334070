import { ActionIcon, Button, Group, LoadingOverlay, Modal, Select, Stack, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React, { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Edit, Plus, X } from "tabler-icons-react";

export function ExpressAddAmbient({expressProject = undefined, projectAmbient = undefined}) {
    
    const [isAddAmbientVisible, setAddAmbientVisibility] = useDisclosure(false);
    const queryClient = useQueryClient();

    const form = useForm({
        initialValues: {
            name: '',
            idExpressAmbientCategory: '',
        },
        validate: {
            name: (value) => (!value || value === '' ? 'Nome não pode ser vazio' : null),
            idExpressAmbientCategory: (value) => (!value || value === '' ? 'Nome não pode ser vazio' : null)
        }
    });

    useEffect(() => {
        if(!projectAmbient)
            return;

        form.setFieldValue('name', projectAmbient.name || '');
        form.setFieldValue('idExpressAmbientCategory', projectAmbient.category.id || '');
    // eslint-disable-next-line
    }, [projectAmbient])

    const {mutate: addOrUpdateAmbient, isLoading} = useMutation((data) => {
        if(expressProject)
            return BackendServiceV2.post('/express/project/' + expressProject?.id + '/ambient', data);

        return BackendServiceV2.put('/express/project/ambient/' + projectAmbient?.id, data);
    }, {
        onSuccess: (response) => {
            queryClient.invalidateQueries('express-project-data');
            form.reset();
            showNotification({
                title: 'Ambiente adicionado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
            setAddAmbientVisibility.close();
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const {data: ambientsCategories, isLoading: isLoadingCategories} = useQuery(['express-ambients-category-data'], () => {
        return BackendServiceV2.get('/express/search/categories');
    });    

    return (<>
        <Modal
            opened={isAddAmbientVisible}
            onClose={setAddAmbientVisibility.close}
            title={<>
                {expressProject &&
                    <Group><Plus /><Title order={2}>Adicionar ambiente</Title></Group>
                }

                {projectAmbient &&
                    <Group noWrap><Edit /><Title order={2} lineClamp={1}>{projectAmbient?.name}</Title></Group>
                }
            </>}
            centered
        >
            <LoadingOverlay visible={isLoading || isLoadingCategories} />

            <form onSubmit={form.onSubmit(values => addOrUpdateAmbient(values))}>
                <Stack>
                    <TextInput
                        required
                        label="Nome"
                        placeholder=""
                        size="md"
                        {...form.getInputProps('name')}
                    />

                    {ambientsCategories &&
                        <Select
                            required
                            label="Ambiente"
                            placeholder="Selecione um"
                            size="md"
                            data={ambientsCategories?.map(category => {return {value: category.id, label: category.name}})}
                            {...form.getInputProps('idExpressAmbientCategory')}
                            withinPortal
                        />
                    }

                    <Button type="submit">{expressProject ? 'Criar' : 'Salvar'}</Button>
                </Stack>
            </form>
        </Modal>
        
        {expressProject &&
            <Button leftIcon={<Plus />} onClick={setAddAmbientVisibility.open}>Adicionar ambiente</Button>
        }

        {projectAmbient &&
            <ActionIcon variant="transparent" onClick={setAddAmbientVisibility.open}><Edit color="#ff5e00" /></ActionIcon>
        }
    </>)
}