import { useSortable } from "@dnd-kit/sortable";
import { ActionIcon, Card, Group, Image, Menu, Text } from "@mantine/core";
import React from "react";
import { ArrowsMove, DotsVertical } from "tabler-icons-react";
import {CSS} from '@dnd-kit/utilities';

import './styles.css';

export function DraggrableCard({id, image, actions, title, onClick = undefined}) {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    if(image) {
        return cardWithImage()
    } else {
        return simpleCard();
    };

    function cardWithImage() {
        return (
            <Card withBorder className="draggrable-card" ref={setNodeRef} style={style} {...attributes}>
                <Card.Section>
                    <ArrowsMove color="white" className="draggrable-icon" {...listeners} />
                    <Image src={image} height={150} {...listeners} fit="cover" />
    
                    <Group position="apart" className="draggrable-title" noWrap>
                        <Text weight={600} lineClamp={1} color="white">{title}</Text>
                        <Menu shadow="md" width={200} keepMounted withinPortal>
                            <Menu.Target>
                                <ActionIcon variant="transparent"><DotsVertical color="#FFFFFF" /></ActionIcon>
                            </Menu.Target>

                            <Menu.Dropdown>
                                {actions?.map(action => (
                                    <>{action}</>
                                ))}
                            </Menu.Dropdown>
                        </Menu>
                    </Group>
                </Card.Section>
            </Card>
        )
    }

    function simpleCard() {
        return (
            <Card withBorder className="draggrable-card simple-card" ref={setNodeRef} style={style} {...attributes}>
                <Group noWrap position="apart" spacing={0}>
                    <Group noWrap spacing={0}>
                        <ArrowsMove className="draggrable-icon no-position" {...listeners} />
                        <Text weight={600} lineClamp={1} className="card-title" onClick={onClick} style={{flex: 1}}><abbr title={title}>{title}</abbr></Text>
                    </Group>

                    <Menu shadow="md" width={200} keepMounted>
                        <Menu.Target>
                            <ActionIcon><DotsVertical /></ActionIcon>
                        </Menu.Target>

                        <Menu.Dropdown>
                            {actions?.map(action => (
                                <>{action}</>
                            ))}
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Card>
        )
    }
    
}