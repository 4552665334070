import { Badge, Button, Group, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import React, { useContext, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";
import equal from'fast-deep-equal';
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";
import { PlayerContext } from "context/Player/PlayerContext";

export function TeleportAddressEdit({teleport}) { 

    const queryClient = useQueryClient();

    const resourcePermission = useContext(ResourcePermissionContext);

    const playerContext = useContext(PlayerContext);

    const form = useForm({
        initialValues: {
            metadata: {
                address: teleport?.metadata?.address || ''
            }
        },
        validate: {
            
        }
    });

    useEffect(() => {
        if(form.getInputProps('metadata.address') !== teleport?.metadata?.address)
            form.setFieldValue('metadata.address', teleport?.metadata?.address || '')
    // eslint-disable-next-line
    }, [teleport]);

    const {mutate: updateTeleport, isLoading} = useMutation((data) => {        
        return BackendServiceV2.put('/teleport/' + teleport.id, data)
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-data');
            playerContext?.player?.reload();
            
            showNotification({
                title: 'Teleport atualizado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const {mutate: updateScanAddress, isLoading: isUpdatingScanAddress} = useMutation((data) => {
        return BackendServiceV2.put('/teleport/' + teleport.id + '/address/update')
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-data');
            playerContext?.player?.reload();
            
            showNotification({
                title: 'Teleport atualizado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });
    
    const [debouncedFormValues] = useDebouncedValue(form.values, 3000);
    
    useEffect(() => {
        form.validate();
    
        if(!form.isValid()) return;
    
        let originalValues = {
            metadata: {
                address: teleport.metadata.address || ''
            }
        };        
    
        if(!equal(originalValues, debouncedFormValues))
            updateTeleport(debouncedFormValues);
    // eslint-disable-next-line
    }, [debouncedFormValues])
    
    const [debouncedAddress] = useDebouncedValue(form.getInputProps('metadata.address').value, 1000);

    return (
        <SectionBox
            title="Localização" 
            description="A localização do seu Teleport" 
            className="full-width"
            isLoading={isLoading || isUpdatingScanAddress}
        >
            <Stack spacing={0}>
                {resourcePermission.containsPermission('TELEPORT_UPDATE') && <>
                    {teleport?.metadata?.addressIsInherited &&
                        <Badge radius={0}>Herdado {teleport?.metadata?.addressFrom === 'MATTERPORT' ? 'das configurações Matterport' : ''}</Badge>
                    }

                    <TextInput
                        size="md"
                        radius={0}
                        {...form.getInputProps('metadata.address')}
                        style={{
                            width: '100%'
                        }}
                    />

                    {teleport.type === 'SCAN' &&
                        <Button radius={0} size="xs" fullWidth={false} onClick={updateScanAddress}>Recarregar da Matterport</Button>
                    }
                </>}

                {form.getInputProps('metadata.address').value && 
                    <iframe
                        title="Teleport Address"
                        width="100%"
                        height="350"
                        loading="lazy"
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                        src={"https://www.google.com/maps/embed/v1/place?key=" + window.ENV.GoogleMapsEmbedKey + "&q=" + (debouncedAddress ? debouncedAddress : 'São Paulo')}>
                    </iframe>
                }

                {false && !form.getInputProps('metadata.address').value && teleport?.metadata?.googleMapsUri &&
                    <iframe
                        title="Teleport Address"
                        width="100%"
                        height="350"
                        loading="lazy"
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                        src={teleport?.metadata?.googleMapsUri}>
                    </iframe>
                }
            </Stack>
        </SectionBox>
    )
}