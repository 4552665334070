import { Alert, Button, Stack, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { ResourcePermissionContext } from "context/Permission/ResourcePermissionContext";
import React from "react";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";

export function ExpressChangeStatus({expressProject}) {

    const queryClient = useQueryClient();
    const modals = useModals();

    const resourcePermission = useContext(ResourcePermissionContext);

    const closeProject = useMutation(() => {
        return BackendServiceV2.put('/express/project/' + expressProject?.id + '/close')
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('express-project-data');
            showNotification({
                title: 'Projeto finalizado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    const closeProjectConfirmation = () => modals.openConfirmModal({
        title: (
            <Text>
                <b>Finalizar configuração do projeto</b>
            </Text>
        ),
        centered: true,
        children: (
            <Stack>
                <Text size="sm">
                    Você tem certeza que deseja finalizar o projeto <b>{expressProject?.name}</b>? 
                </Text>

                <Alert color="blue">
                    Você não poderá mais editar o projeto após finalizar. É necessário finalizar o projeto para iniciar sua produção!
                </Alert>
            </Stack>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: async () => {
            closeProject.mutate();
        }
    });

    const openProject = useMutation(() => {
        return BackendServiceV2.put('/express/project/' + expressProject?.id + '/open')
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('express-project-data');
            showNotification({
                title: 'Projeto reaberto com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (<>
        {expressProject?.status === 'OPEN' && resourcePermission.containsPermission('EXPRESS_PROJECT_CLOSE') &&
            <Button fullWidth onClick={closeProjectConfirmation}>Finalizar</Button>
        }

        {expressProject?.status === 'CLOSED' && resourcePermission.containsPermission('EXPRESS_PROJECT_OPEN') &&
            <Button fullWidth onClick={openProject.mutate}>Reabrir</Button>
        }
    </>)
}